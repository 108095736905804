// /resources/js/vuetify/index.js

import Vue from 'vue'
import Vuetify from 'vuetify'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    },
    theme: {
        themes: {
            light: {
              primary: '#161C35',
              secondary: '#00ffff',
              accent: '#00FFFF',
              error: '#B71C1C',
              background: '#F5F5F5',
              pink: "#CF2857"
            },
            dark: {
              primary: '#161C35',
              secondary: '#00ffff',
              accent: '#00FFFF',
              error: '#B71C1C',
              background: '#F5F5F5',
              pink: "#CF2857"
            },
        },
    },
}

let vuetify = new Vuetify(opts);

export default vuetify
