<template>
    <div>
        <NavDrawer></NavDrawer>
        <v-main class="ml-11" :style="{background: this.$vuetify.theme.themes.light.background}">
            <v-container fluid>
                <breadcrumbs class="px-7 pt-6 pb-0" />
                <router-view class="px-7"/>
            </v-container>
        </v-main>
    </div>
</template>

<script>
import NavDrawer from '@/components/NavDrawer';
import Breadcrumbs from "@/components/Breadcrumbs";

export default {
    components: {
        Breadcrumbs,
        NavDrawer,
    },
}
</script>

<style lang="scss">
</style>
