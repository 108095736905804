export default {
    LOGIN: {
        NAME: 'login',
        PATH: 'auth/login',
    },
    LOGOUT: {
        NAME: 'logout',
        PATH: 'auth/logout',
    },
};
