<template>
    <v-main app class="login-remember">
        <v-container fluid class="d-flex">
            <div v-if="successMessage">
                {{ successMessage }}
            </div>
            <v-form v-else ref="form" v-model="valid" lazy-validation @submit="handleSubmit" class="pa-8 elevation-5 rounded-lg">

                <h1 class="font-weight-bold text-h5 mb-6">Forgot Password</h1>
                <v-row>
                    <v-col cols="12">
                        <label for="forgot-password" class="font-weight-bold text-subtitle-2">Email</label>
                        <v-text-field
                            placeholder="example@example.com"
                            v-model="auth.email"
                            :rules="emailRules"
                            type="email"
                            required
                            single-line
                            hide-details="auto"
                            flat
                            solo
                            dense
                            background-color="background"
                            id="forgot-password"
                            class="rounded-0 mt-2"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <div class="text-right mt-3">
                    <v-btn :to="{name: 'auth.login'}" depressed rounded class="mt-4 px-7 text-uppercase white--text" color="primary">
                        Back to Login
                    </v-btn>
                    <v-btn type="submit" depressed rounded class="mt-2 px-4 text-uppercase white--text" color="accent">
                        {{ processing ? "Please wait" : "Send Reset Email" }}
                    </v-btn>
                </div>
            </v-form>
        </v-container>
    </v-main>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    data: () => ({
        successMessage: null,
        processing: false,
        valid: true,
        auth: {
            email: '',
        },
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
    }),
    methods: {
        ...mapActions({
            signIn: 'auth/login'
        }),
        validate () {
            this.$refs.form.validate();
        },
        reset () {
            this.$refs.form.reset();
        },
        resetValidation () {
            this.$refs.form.resetValidation();
        },
        async handleSubmit(event) {
            event.preventDefault();
            this.processing = true;
            await axios.post(route('password.email'), this.auth)
                .then(({ data }) => {
                    this.successMessage = data.message;
                })
                .catch(({ response: { data } }) => {
                    alert(data.message);
                })
                .finally(() => {
                    this.processing = false;
                });
        },
    },
};
</script>

<style scoped lang="scss">
    .v-form {
        max-height: 325px;
    }
</style>
