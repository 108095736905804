import axios from 'axios';
import store from '@/store';
import authActionTypes from '@/store/modules/auth/action-types';

/**
 * Quickfire Axios Interceptors
 * @see: https://axios-http.com/docs/interceptors
 */

/**
 * If any request returns "401 Unauthorized", it's likely that either the CSRF token has expired, or the session has expired.
 * If just the token, refresh it.
 * If the session, log user out of SPA, and redirect to login page.
 * @todo: Implement token refresh. Currently only log out.
 */
axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.status === 401) {
        store.dispatch(authActionTypes.LOGOUT.PATH);
    }
    return Promise.reject(error);
});
