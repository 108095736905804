<template>
    <v-breadcrumbs :items="crumbs" divider=">" />
</template>

<script>
export default {
    computed: {
        crumbs: function() {
            let pathArray = this.$route.path.split("/");
            pathArray.shift()
            let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
                if(this.$route.matched[idx] !== undefined) {
                    breadcrumbArray.push({
                        path: path,
                        to: breadcrumbArray[idx - 1]
                            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
                            : "/" + path,
                        text: this.$route.matched[idx].meta.breadcrumb || path,
                        disabled: false,
                        exact: true
                    });
                }
                return breadcrumbArray;
            }, []);
            return breadcrumbs;
        }
    }
};
</script>

<style scoped lang="scss">
</style>
