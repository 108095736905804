<template>
    <v-app>
        <template v-if="isLoggedIn">
            <NavBar></NavBar>
        </template>
        <router-view></router-view>
        <v-snackbar
            v-for="alert in this.$store.state.alerts" :key="alert.uuid"
            v-model="alert.visible"
            multi-line
            :color="alert.type"
        >
            {{ alert.text }}
        </v-snackbar>

        <template v-if="isLoggedIn">
            <!-- <v-footer app bottom fixed padless>
                <v-col
                    class="text-center"
                    cols="12"
                >
                    <a href="https://quickfiredigital.com" target="_blank">Quickfire Digital</a> — <a href="/download/qfconnector" target="_blank">Learn More</a>
                </v-col>
            </v-footer> -->
        </template>
    </v-app>
</template>


<script>
import NavBar from '@/components/NavBar';

export default {
    components: {
        NavBar,
    },
    computed : {
        isLoggedIn : function(){
            return this.$store.state.auth.authenticated
        }
    }
}
</script>

<style scoped lang="scss">

</style>
